import i18n from '@i18n/i18n';
export const compareGridValues = () => {
  return {
    columnHeaders: [
      {
        text: i18n.t('compare:columnHeadings.community'),
        mobileText: i18n.t('compare:mobileHeadings.community'),
        link: 'community',
      },
      {
        text: i18n.t('compare:columnHeadings.personal'),
        mobileText: i18n.t('compare:mobileHeadings.personal'),
        link: 'personal',
      },
      {
        text: i18n.t('compare:columnHeadings.professional'),
        mobileText: i18n.t('compare:mobileHeadings.professional'),
        link: 'professional',
      },
      {
        text: i18n.t('compare:columnHeadings.organization'),
        mobileText: i18n.t('compare:mobileHeadings.organization'),
        link: 'organization',
      },
      {
        text: i18n.t('compare:columnHeadings.onetime'),
        mobileText: i18n.t('compare:mobileHeadings.onetime'),
        link: 'boardmakerLicense',
      },
    ],
    rows: [
      {
        header: true,
        last: false,
        text: i18n.t('compare:rowHeadings.editor'),
        values: [],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.activations'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.unlimited'),
          i18n.t('compare:rowValues.unlimited'),
          i18n.t('compare:rowValues.unlimited'),
          i18n.t('compare:rowValues.byDevice'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.languages'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.languages'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.licensing'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.login'),
          i18n.t('compare:rowValues.login'),
          i18n.t('compare:rowValues.login'),
          i18n.t('compare:rowValues.byKey'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.pcsSymbols'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.symbols'),
          i18n.t('compare:rowValues.symbols'),
          i18n.t('compare:rowValues.symbols'),
          i18n.t('compare:rowValues.symbols'),
        ],
      },
      {
        header: false,
        last: true,
        text: i18n.t('compare:rowHeadings.templates'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.templates'),
          i18n.t('compare:rowValues.templates'),
          i18n.t('compare:rowValues.templates'),
          i18n.t('compare:rowValues.templates'),
        ],
      },
      {
        header: true,
        last: false,
        text: i18n.t('compare:rowHeadings.studentCenter'),
        values: [],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.playActivitiesInstructor'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.playActivitiesStudent'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: true,
        text: i18n.t('compare:rowHeadings.languages'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.languages'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: true,
        last: false,
        text: i18n.t('compare:rowHeadings.myBoardmaker'),
        values: [],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.instructorManagement'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.studentManagement'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.assignStudentActivities'),
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.cloudStorage'),
        values: [
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.createAndJoin'),
        values: [
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
      {
        header: false,
        last: false,
        text: i18n.t('compare:rowHeadings.accessCommunity'),
        values: [
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
      {
        header: false,
        last: true,
        text: i18n.t('compare:rowHeadings.shareActivity'),
        values: [
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
      {
        header: true,
        last: false,
        text: i18n.t('compare:rowHeadings.curriculum'),
        values: [],
      },
      {
        header: false,
        last: false,
        text: 'Boardmaker Activities to Go',
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
      {
        header: false,
        last: false,
        text: 'Instructional Solutions',
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: false,
        text: 'Premium Activities',
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.no'),
        ],
      },
      {
        header: false,
        last: true,
        text: 'Core First Learning',
        values: [
          i18n.t('compare:rowValues.no'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yes'),
          i18n.t('compare:rowValues.yesNote'),
        ],
      },
    ],
  };
};

import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import { useTranslation } from 'react-i18next';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from 'reactstrap';
import { compareGridValues } from '@data/compareGridValues';

const RowData = ({ value, index, rowIndex, isLastRow, borderStart }) => {
  var className = '';
  if (index >= borderStart) {
    className += 'bordered-cell';
  }
  if (isLastRow) {
    className += ' row-last';
  }

  return (
    <td key={'cellIndex' + rowIndex + ':' + index} className={className}>
      {value}
    </td>
  );
};

export default function Compare() {
  const { t } = useTranslation();
  const pageTitle = t('page:comparison');
  const columns = compareGridValues().columnHeaders.length;
  const [selectedProduct, setSelectedProduct] = useState({index: 0, path:'community'});
  return (
    <>
      <SEO title={pageTitle} />
      <ScrollingContainer className="container">
        <div className="row">
          <div className="col-12">
            <span className="h1">{t('compare:general.title')}</span>
          </div>  
        </div>
        <div className="row d-none d-sm-block">
          <div className="col-12 compare-intro">
            <span className="display-4 small">{t('compare:general.intro')}</span>
            <hr className="hr" />
          </div>
        </div>
        <div className="row d-block d-sm-none">
          <br/>
          <div className="col-12 compare-intro">
            <span className='compare-mobile-intro'>{t('compare:general.intro')}</span>
            <hr className="hr" />
          </div>
        </div>
        <div className="row d-flex d-sm-none">
          <div className='col-7'>
            <UncontrolledDropdown>
              <DropdownToggle className="column-dropdown" color="primary" caret>
                {compareGridValues().columnHeaders[selectedProduct.index].mobileText}
              </DropdownToggle>
              <DropdownMenu>
              {compareGridValues().columnHeaders.map((heading, index) => {
                return (
                    <DropdownItem key={index} onClick={() => {setSelectedProduct({index: index, path: heading.link})}}>
                      {heading.mobileText}
                    </DropdownItem>
                  )}
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className='col-5'>
            <StyledLink className="learn-more-link">
              <Link
                id={'LearnMore' + selectedProduct.path}
                data-testid={'LearnMore' + selectedProduct.path}
                to={`/${selectedProduct.path}`}
              >
                {t('action.learnMore')}&nbsp;&rsaquo;
              </Link>
            </StyledLink>
          </div>
        </div>
        <br/>
        <ScrollTable>
        <div className="row d-block d-sm-none">
          <div className='col-12'>
            <table className="td-table">
              <tbody>
                {compareGridValues().rows.map((row, rowIndex) => {
                  return (
                    <tr
                      key={'rowIndex' + rowIndex}
                      className={row.header && rowIndex > 0 ? 'row-group' : null}
                    >
                      {row.header ? (
                        <th className="row-header" colSpan="2">{row.text}</th>
                      ) : (
                        <td
                          className={
                            row.last ? 'row-header row-last' : 'row-header'
                          }
                        >
                          {row.text}
                        </td>
                      )}
                      {!row.header ? (<td>{row.values[selectedProduct.index]}</td>) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>   
          </div>
        </div>
        <div className="row d-flex d-sm-none">
          <div className="col-md-12 footnotes">
            <p className="compare-note display-4 small">
              {t('compare:general.note1')}
            </p>
            <p className="compare-note display-4 small">
              {t('compare:general.note2')}
            </p>
            <p className="compare-note display-4 small">
              {t('compare:general.note3')}
            </p>
          </div>
        </div>
        </ScrollTable>
      </ScrollingContainer>
        <div className="row d-none d-sm-block">
          <div className="col-12">
            <table className="td-table">
              <thead>
                <tr>
                  <th colSpan="2">&nbsp;</th>
                  <th colSpan="3" className="table-header">
                    <span>{t('compare:general.tableheader')}</span>
                  </th>
                  <th>&nbsp;</th>
                </tr>
                <tr className="row_last">
                  <th>&nbsp;</th>
                  {compareGridValues().columnHeaders.map((heading, index) => {
                    return (
                      <th
                        className={
                          index >= 1
                            ? 'column-header bordered-cell'
                            : 'column-header'
                        }
                        key={'colHead' + index}
                        id={'colHead' + index}
                      >
                        {heading.text}
                        <UncontrolledTooltip placement="top" target={'colHead' + index} hideArrow={false} modifiers={{offset:{offset: '0,0,-20'}, flip:{enabled: false}}}>{heading.text}</UncontrolledTooltip>
                        <div className="learn-more-link">
                          <Link
                            id={'LearnMore' + heading.text}
                            data-testid={'LearnMore' + heading.text}
                            to={`/${heading.link}`}
                          >
                            {t('action.learnMore')}&nbsp;&rsaquo;
                          </Link>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {compareGridValues().rows.map((row, rowIndex) => {
                  return (
                    <tr
                      key={'rowIndex' + rowIndex}
                      className={row.header && rowIndex > 0 ? 'row-group' : null}
                    >
                      {row.header ? (
                        <th className="row-header">{row.text}</th>
                      ) : (
                        <td
                          className={
                            row.last ? 'row-header row-last' : 'row-header'
                          }
                        >
                          {row.text}
                        </td>
                      )}
                      {row.values.map((value, cellIndex) => {
                        return (
                          <RowData
                            value={value}
                            index={cellIndex}
                            rowIndex={rowIndex}
                            isLastRow={row.last}
                            borderStart={1}
                            key={'cellIndex' + cellIndex}
                          />
                        );
                      })}
                      {(function(cells, i, len) {
                        while (++i <= len) {
                          cells.push(
                            <RowData
                              value=""
                              index={i}
                              rowIndex={rowIndex}
                              isLastRow={row.last}
                              borderStart={2}
                              key={'borderedCellIndex' + i}
                            />
                          );
                        }
                        return cells;
                      })([], row.values.length, columns)}
                    </tr>
                  );
                })}
              </tbody>
            </table>      
          </div>
        </div>   
        <br />
        <div className="row d-none d-sm-block">
          <div className="col-md-12 footnotes">
            <p className="compare-note display-4 small">
              {t('compare:general.note1')}
            </p>
            <p className="compare-note display-4 small">
              {t('compare:general.note2')}
            </p>
            <p className="compare-note display-4 small">
              {t('compare:general.note3')}
            </p>
          </div>
        </div>
    </>
  );
}

const StyledLink = styled.div`
{
  padding-top: 10px;
}
`;

const ScrollingContainer = styled.div`
{
  position: relative;
}`;

const ScrollTable = styled.div`
{
  max-height: 400px;    
  overflow-y: auto;
  overflow-x: hidden;
}`;

const ProductDropdownToggle = styled(DropdownToggle)`
  background: transparent;
  border: 1px solid #0;
  color: black;
  :hover {
    background: transparent;
    border: 1px solid #0;
    color: black;
  }
`;